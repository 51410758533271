<script setup lang="ts">
import { frontpageQuery } from '~/queries/contentQueries'
const { data, error } = await useSanityData<any>({ query: frontpageQuery })

const photo = computed(() => data.value?.mainImage)
const hasPhoto = computed(
  () => !!(photo.value && Object.keys(photo.value).length),
)

usePageHead({
  title: data.value?.seo?.title || data.value?.title,
  seo: data.value?.seo,
})
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${data.value?.seo?.title} - ${titleChunk}` : data.value?.seo?.title
  },
  link: [
    {
      rel: 'canonical',
      href: `https://fotostorie.no/`,
    },
  ],
})
</script>
<template>
  <NuxtLayout>
    <template #photo>
      <AppPhotoPoster v-if="hasPhoto" :texts="data?.headerImageText || {}" align="right" vertical-align="bottom">
        <MediaBaseImage 
          v-if="hasPhoto" 
          use-hotspot 
          :image="photo" 
          :alt="photo?.alt ?? ''"
          :priority="true"
          class="flex-img animation-delay-0 animate duration-200 fadeIn mx-auto mb-2 h-full inset-shadow-lg brightness-70" 
        />
      </AppPhotoPoster>
    </template>
    <PageBuilder v-if="data && data.pageBuilder" :data="data.pageBuilder" />
  </NuxtLayout>
</template>